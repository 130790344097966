* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  background: #010101;
}

#root {
  overflow: hidden;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto,
    segoe ui, arial, sans-serif;
}

.main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  pointer-events: none;
}

.main > div {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 700px) {
  #root {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
  }
  .canvas-container {
    height: unset;
    flex: 1 0 0px;
    margin-bottom: -100px;
  }
  .canvas-container::after {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100px;
    width: 100%;
    background: linear-gradient(0deg, black, transparent);
  }

  .main {
    position: relative;
    flex: 0 0 auto;
    height: auto;
  }

  .main > div {
    position: relative;
    height: auto;
  }
}

@media only screen and (max-width: 500px) {
  .canvas-container {
    margin-bottom: -40px;
  }
  .canvas-container::after {
    display: none;
  }
}

.bg {
  background-image: url('https://uploads.codesandbox.io/uploads/user/b3e56831-8b98-4fee-b941-0e27f39883ab/DWLR-bg.jpg');
}

.stars {
  background-image: url('https://uploads.codesandbox.io/uploads/user/b3e56831-8b98-4fee-b941-0e27f39883ab/OiNX-stars.png');
}

.ground {
  background-image: url('https://uploads.codesandbox.io/uploads/user/b3e56831-8b98-4fee-b941-0e27f39883ab/xyC5-ground.png');
}

.main > .code {
  position: absolute;
  right: 10vw;
  margin-right: -60px;
  width: 640px;
  max-width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.code-container {
  position: relative;
  margin-bottom: -60px;
}

.counter {
  position: absolute;
  top: -100px;
  right: -20px;
  color: white;
  background: #394a52;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 16px 40px -5px rgba(0, 0, 0, 0.5);
  width: 120px;
  height: 120px;
  font-size: 3em;
  z-index: 20000;
  pointer-events: all;
}

.counter > span {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -10px;
  transform: translate3d(-50%, -50%, 0);
}

.counter > button {
  margin: 10px;
  padding: 5px 10px;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100px;
  border-radius: 5px;
  border: solid 2px white;
  outline: none;
  background: transparent;
  color: white;
  cursor: pointer;
}

.code-container pre[class*='language-'] {
  margin-top: -50px;
  display: inline-block;
  width: auto !important;
  padding: 40px 50px 40px 45px;
  font-size: 0.8rem !important;
  border-radius: 10px !important;
  box-shadow: 0 16px 40px -5px rgba(0, 0, 0, 0.5);
  white-space: pre-wrap !important;
  pointer-events: all;
}

@media only screen and (max-width: 700px) {
  .main > .code {
    position: relative;
    height: auto;
    width: 100%;
    max-width: 100%;
    margin: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  .code-container {
    bottom: 0;
    margin-bottom: -60px;
  }
  .code-container > pre[class*='language-'] {
    font-size: 0.6rem;
    padding: 20px 50px 70px 20px;
    border-radius: 10px 10px 0 0 !important;
  }
  .counter {
    top: -100px;
    right: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .code-container {
    width: 100%;
  }

  .code-container > pre[class*='language-'] {
    font-size: 0.6rem !important;
    padding: 20px 20px 70px;
    width: 100% !important;
  }

  .counter {
    top: 70px;
    right: 20px;
  }
}

@media only screen and (max-height: 600px) {
  .code-container {
    bottom: 0;
    margin-bottom: -60px;
  }
  .code-container > pre[class*='language-'] {
    font-size: 0.6rem;
    padding: 20px 20px 70px;
  }

  .counter {
    transform: scale(0.8);
    transform-origin: bottom right;
    top: 100px;
    right: -30px;
  }
}
@media only screen and (max-width: 600px) {
  .counter {
    top: -160px;
    right: 20px;
  }
}

@media only screen and (max-width: 430px) {
  .code-container {
    margin-bottom: -60px;
  }
  .code-container > pre[class*='language-'] {
    font-size: 0.6rem;
    padding: 20px 20px 70px;
  }

  .counter {
    transform: scale(0.8);
    transform-origin: bottom right;
    top: -160px;
    right: 20px;
  }
}
@media only screen and (max-height: 480px) {
  .counter {
    transform: scale(0.8);
    transform-origin: bottom right;
    bottom: 70px;
    right: 20px;
    top: unset;
  }
}

@media only screen and (max-height: 500px) {
  .code-container > pre[class*='language-'] {
    font-size: 0.5rem !important;
  }

  .counter {
    top: 40px;
    right: 20px;
  }
}

@media only screen and (max-height: 500px) and (min-width: 600px) {
  .counter {
    top: 40px;
    right: -80px;
  }
}

@media only screen and (max-height: 360px) and (min-width: 600px) {
  .counter {
    top: 50px;
    right: -80px;
  }
}

.bear {
  top: 12.5%;
  height: 80% !important;
  min-height: 600px;
  left: -19%;
  background-size: 1000px !important;
  background-image: url('https://uploads.codesandbox.io/uploads/user/b3e56831-8b98-4fee-b941-0e27f39883ab/TiR4-bear.png');
}

.leaves1 {
  left: -2.5%;
  top: -2.5%;
  width: 105% !important;
  height: 105% !important;
  background-image: url('https://uploads.codesandbox.io/uploads/user/b3e56831-8b98-4fee-b941-0e27f39883ab/zjhf-leaves1.png');
}

.leaves2 {
  left: -2.5%;
  top: -2.5%;
  width: 105% !important;
  height: 105% !important;
  background-image: url('https://uploads.codesandbox.io/uploads/user/b3e56831-8b98-4fee-b941-0e27f39883ab/3n-p-leaves2.png');
}

span.header-left {
  font-weight: 700;
  text-transform: uppercase;
  position: absolute;
  display: inline-block;
  top: 30px;
  left: 40px;
  font-size: 3em;
  color: white;
  z-index: 1000;
}

@media only screen and (max-width: 1200px) {
  .bear {
    left: -20%;
  }
}

@media only screen and (max-width: 1140px) {
  .bear {
    left: -25%;
  }
}

@media only screen and (max-width: 1024px) {
  .bear {
    left: -20%;
  }
  .code {
    justify-content: center;
    z-index: 1000;
    margin-left: 0;
  }
  pre[class*='language-'] {
    font-size: 0.7rem !important;
  }
}

a {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto,
    segoe ui, arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: inherit;
  position: absolute;
  display: inline;
  text-decoration: none;
  z-index: 1;
  pointer-events: all;
}

a.top-left {
  font-size: 0.8em;
  top: 90px;
  left: 40px;
}

.nav {
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  left: 40px;
  min-height: 38px;
  position: fixed;
  right: 40px;
  top: 40px;
  z-index: 1000;
}

.nav a {
  position: relative;
  flex: 0 0 auto;
}

a.bottom-left {
  bottom: 40px;
  left: 40px;
}

a.bottom-right {
  bottom: 40px;
  right: 40px;
}

.main .bottom {
  z-index: 1000;
}

@media only screen and (max-width: 700px) {
  .main .bottom {
    bottom: 0;
    height: 60px;
    background-color: rgba(0, 0, 0, 0.2);
  }

  a.bottom-left {
    bottom: 20px;
    left: 20px;
  }

  a.bottom-right {
    bottom: 20px;
    right: 20px;
  }
  a.top-left {
    position: fixed;
    font-size: 0.8em;
    top: 90px;
    left: 40px;
  }

  .nav {
    justify-content: flex-start;
    left: 40px;
    right: 40px;
    top: 90px;
  }

  span.header-left {
    position: fixed;
    top: 30px;
    left: 40px;
  }
}

@media only screen and (max-width: 500px) {
  pre[class*='language-'] {
    font-size: 0.6rem !important;
  }
  span.header-left {
    top: 10px;
    left: 20px;
  }
  a.top-left {
    top: 70px;
    left: 20px;
  }

  .nav {
    left: 20px;
    right: 20px;
    top: 60px;
  }

  a.bottom-left {
    left: 20px;
  }

  a.bottom-right {
    right: 20px;
  }
}

.snippet-container {
  display: flex;
  align-items: center;
  gap: 4px;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 5px;
}

.snippet-lang {
  background-color: #272822;
  color: #fff;
  outline: 0;
  border: 0;
}

.copy-button {
  box-shadow: none;
  text-decoration: none;
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  pointer-events: all;
  padding: 12px;
  width: auto;
  border-radius: 5px;
  border: 0;
  outline: none;
  background: transparent;
  color: #f8f9fa;
  cursor: pointer;
}

.copy-button:hover {
  background-color: #5f5e5d;
}
